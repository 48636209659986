
console.log(process.env.NODE_TARGET);
import { getHostUrl } from '@/utils/common'
const resultCode = {
  NO_LOGIN: '10001',
  SUCCESS: '10000',
  SUCCESSE: '0',
  enterprise_username_repeat: '10005',
  enterprise_username_repeatError: '10011',
  SUCCESS_ZERO: '0',
  URL: getHostUrl(),
}

export default resultCode
