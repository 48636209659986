import layout from "../layout/index.vue";

export const constantRoutes = [
  {
    path: "/Tostayin",
    name: "Tostayin",
    component: () => import("@/views/Tostayin"),
    meta: {
      title: "商家入驻",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login/login"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    component: layout,
    children: [
      {
        path: "/",
        name: "Index",
        component: () => import("@/views/Index/index"),
        meta: {
          requireAuth: true,
          title: "首页",
        },
      },
      {
        path: "/Productlist",
        name: "Productlist",
        component: () => import("@/views/Productlist/index"),
        meta: {
          requireAuth: true,
          title: "商品列表",
        },
      },
      // {
      // 	path: '/',
      // 	name: 'Productlist',
      // 	component: () => import('@/views/Productlist/index'),
      // 	meta: {
      // 		requireAuth: true,
      // 		title: "商品列表"
      // 	}
      // },
      {
        path: "/add",
        name: "add",
        component: () => import("@/views/Productlist/add"),
        meta: {
          requireAuth: true,
          title: "添加商品",
        },
      },
      {
        path: "/goodsaudit",
        name: "goodsaudit",
        component: () => import("@/views/Productlist/goodsaudit"),
        meta: {
          requireAuth: true,
          title: "审核商品",
        },
      },
      {
        path: "/Productlistdetails/:id",
        name: "details",
        component: () => import("@/views/Productlist/details"),
        meta: {
          requireAuth: true,
          title: "商品详情",
        },
      },
      {
        path: "/editBase/:id",
        name: "editBase",
        component: () => import("@/views/Productlist/editBase/index"),
        meta: {
          requireAuth: true,
          title: "编辑商品信息",
        },
      },
      {
        path: "/mealInfo/:id",
        name: "mealInfo",
        component: () => import("@/views/Productlist/mealInfo/index"),
        meta: {
          requireAuth: true,
          title: "套餐信息",
        },
      },
      {
        path: "/mealInfoeditor/:id",
        name: "mealInfoeditor",
        component: () => import("@/views/Productlist/mealInfo/editor"),
        meta: {
          requireAuth: true,
          title: "编辑套餐",
        },
      },
      {
        path: "/mealInfoeditoradd/:id/:ids",
        name: "mealInfoeditoradd",
        component: () => import("@/views/Productlist/mealInfo/add"),
        meta: {
          requireAuth: true,
          title: "添加套餐",
        },
      },

      // 店铺管理
      {
        path: "/editBack/:id",
        name: "editBack",
        component: () => import("@/views/Shopmanagement/editBack"),
        meta: {
          requireAuth: true,
          title: "编辑归还地址",
        },
      },
      {
        path: "/addBack",
        name: "addBack",
        component: () => import("@/views/Shopmanagement/addBack"),
        meta: {
          requireAuth: true,
          title: "添加归还地址",
        },
      },

      // 平台
      {
        path: "/regularOrder",
        name: "regularOrder",
        component: () => import("@/views/Order/platformOrder/regularOrder"),
        meta: {
          requireAuth: true,
          title: "常规订单",
        },
      },
      {
        path: "/overdueOrder",
        name: "overdueOrder",
        component: () => import("@/views/Order/platformOrder/overdueOrder"),
        meta: {
          requireAuth: true,
          title: "逾期订单",
        },
      },
      {
        path: "/closingOrder",
        name: "closingOrder",
        component: () => import("@/views/Order/platformOrder/closingOrder"),
        meta: {
          requireAuth: true,
          title: "成交订单",
        },
      },
      {
        path: "/closeOrder",
        name: "closeOrder",
        component: () => import("@/views/Order/platformOrder/closeOrder"),
        meta: {
          requireAuth: true,
          title: "结清订单",
        },
      },

      // 供应商
      {
        path: "/regularOrderG",
        name: "regularOrderG",
        component: () => import("@/views/Order/supplierOrder/regularOrder"),
        meta: {
          requireAuth: true,
          title: "常规订单",
        },
      },
      {
        path: "/overdueOrderG",
        name: "overdueOrderG",
        component: () => import("@/views/Order/supplierOrder/overdueOrder"),
        meta: {
          requireAuth: true,
          title: "逾期订单",
        },
      },
      {
        path: "/closingOrderG",
        name: "closingOrderG",
        component: () => import("@/views/Order/supplierOrder/closingOrder"),
        meta: {
          requireAuth: true,
          title: "成交订单",
        },
      },
      {
        path: "/billingDetails/:id",
        name: "billingDetails",
        component: () => import("@/views/Order/billingDetails"),
        meta: {
          requireAuth: true,
          title: "账单明细",
        },
      },
      {
        path: "/closeOrderG",
        name: "closeOrderG",
        component: () => import("@/views/Order/supplierOrder/closeOrder"),
        meta: {
          requireAuth: true,
          title: "结清订单",
        },
      },
      {
        path: "/orderInfo/:id",
        name: "orderInfo",
        component: () => import("@/views/Order/orderInfo"),
        meta: {
          requireAuth: true,
          title: "订单详情",
        },
      },
      {
        path: "/Ordereditor",
        name: "Ordereditor",
        component: () => import("@/views/Order/editor"),
        meta: {
          requireAuth: true,
          title: "编辑套餐",
        },
      },

      // 店铺管理
      {
        path: "/returnAddress",
        name: "returnAddress",
        component: () => import("@/views/Shopmanagement/returnAddress"),
        meta: {
          requireAuth: true,
          title: "归还地址设置",
        },
      },
      {
        path: "/return",
        name: "return",
        component: () => import("@/views/Shopmanagement/return"),
        meta: {
          requireAuth: true,
          title: "归还审核",
        },
      },
      {
        path: "/rentAddress",
        name: "rentAddress",
        component: () => import("@/views/Shopmanagement/rentAddress"),
        meta: {
          requireAuth: true,
          title: "租赁地址",
        },
      },
      {
        path: "/business",
        name: "business",
        component: () => import("@/views/Shopmanagement/business"),
        meta: {
          requireAuth: true,
          title: "店铺信息",
        },
      },
      {
        path: "/stores",
        name: "stores",
        component: () => import("@/views/Shopmanagement/stores"),
        meta: {
          requireAuth: true,
          title: "旺铺管理",
        },
      },
      {
        path: "/swiper_add",
        name: "swiper_add",
        component: () => import("@/views/Shopmanagement/swiper_add"),
        meta: {
          requireAuth: true,
          title: "添加轮播图",
        },
      },

      // 退款管理
      {
        path: "/refund",
        name: "refund",
        component: () => import("@/views/refund/refund"),
        meta: {
          requireAuth: true,
          title: "仅退款列表",
        },
      },

      // 账号管理
      {
        path: "/usersList",
        name: "usersList",
        component: () => import("@/views/user/usersList"),
        meta: {
          requireAuth: true,
          title: "账号列表",
        },
      },

      // 权限管理
      {
        path: "/roleList",
        name: "roleList",
        component: () => import("@/views/roleList/roleList"),
        meta: {
          requireAuth: true,
          title: "权限列表",
        },
      },
      {
        path: "/addRole",
        name: "addRole",
        component: () => import("@/views/roleList/addRole"),
        meta: {
          requireAuth: true,
          title: "添加角色",
        },
      },
      {
        path: "/updateRole/:id",
        name: "updateRole",
        component: () => import("@/views/roleList/updateRole"),
        meta: {
          requireAuth: true,
          title: "编辑角色",
        },
      },

      // 账户管理
      {
        path: "/myAccount",
        name: "myAccount",
        component: () => import("@/views/account/myAccount"),
        meta: {
          requireAuth: true,
          title: "我的账户",
        },
      },
      // {
      // 	path: '/recharge',
      // 	name: 'recharge',
      // 	component: () => import('@/views/account/recharge'),
      // 	meta: {
      // 		requireAuth: true,
      // 		title: "充值中心"
      // 	}
      // },
      {
        path: "/czcenter",
        name: "czcenter",
        component: () => import("@/views/account/czcenter"),
        meta: {
          requireAuth: true,
          title: "充值接口",
        },
      },

      {
        path: "/bill",
        name: "bill",
        component: () => import("@/views/account/bill"),
        meta: {
          requireAuth: true,
          title: "对账流水",
        },
      },
      {
        path: "/record",
        name: "record",
        component: () => import("@/views/account/record"),
        meta: {
          requireAuth: true,
          title: "对账流水",
        },
      },
      {
        path: "/recharge",
        name: "recharge",
        component: () => import("@/views/account/recharge"),
        meta: {
          requireAuth: true,
          title: "风控充值",
        },
      },
      {
        path: "/rechargedetails/:id",
        name: "rechargedetails",
        component: () => import("@/views/account/recharge/details"),
        meta: {
          requireAuth: true,
          title: "风控消费详情",
        },
      },
      {
        path: "/orderFinancialList",
        name: "orderFinancialList",
        component: () => import("@/views/account/orderFinancialList"),
        meta: {
          requireAuth: true,
          title: "订单财务列表",
        },
      },
      {
        path: "/billFinancialList",
        name: "billFinancialList",
        component: () => import("@/views/account/billFinancialList"),
        meta: {
          requireAuth: true,
          title: "账单财务列表",
        },
      },
      {
        path: "/details_1",
        name: "details_1",
        component: () => import("@/views/account/details_1"),
        meta: {
          requireAuth: true,
          title: "提现详情", //提现成功
        },
      },
      {
        path: "/details_2",
        name: "details_2",
        component: () => import("@/views/account/details_2"),
        meta: {
          requireAuth: true,
          title: "提现详情", //提交提现申请
        },
      },
      {
        path: "/repayment",
        name: "repayment",
        component: () => import("@/views/account/repayment"),
        meta: {
          requireAuth: true,
          title: "还款记录",
        },
      },

      {
        path: "/classRun",
        name: "classRun",
        component: () => import("@/views/Class/classRun"),
        meta: {
          requireAuth: true,
          title: "品类管理",
        },
      },
      {
        path: "/applyClass",
        name: "applyClass",
        component: () => import("@/views/Class/applyClass"),
        meta: {
          requireAuth: true,
          title: "申请品类",
        },
      },
    ],
  },
];

/**
 * 需要根据用户角色动态加载的路由
 */

// 所有上面定义的路由都要写在下面的routers里
export let constRouters = [constantRoutes];
